import { faqs } from '@app/constants';
import Chevron from '@app/components/icons/chevron';
import { useState } from 'react';
import cn from 'classnames';
import { useScrollContext } from '@app/context/scroll-context';

export default function FAQ() {
    const [currentExpandedId, setExpendedId] = useState(0);

    const handleExpand = (id: number) => {
        if (currentExpandedId === id) {
            setExpendedId(0);
            return;
        }
        setExpendedId(id);
    };

    const { faqsRef } = useScrollContext();

    return (
        <div ref={faqsRef} className="flex w-full flex-col py-[60px] text-center sm:py-[120px]">
            <p className="h2-responsive mb-9 text-text-primary sm:mb-14">{faqs.title}</p>
            <div className="flex w-full flex-col items-center gap-10">
                {faqs.questionAnswerList.map((questionAnswer) => {
                    return (
                        <div key={questionAnswer.id} className="flex w-full max-w-[1126px] flex-col border-b border-b-border-primary pb-8 text-center">
                            <div className="flex cursor-pointer items-center justify-center gap-3" onClick={() => handleExpand(questionAnswer.id)}>
                                <p className={cn(currentExpandedId === questionAnswer.id ? 'body1-responsive font-semibold text-text-primary' : 'h4-responsive font-medium text-text-secondary')}>{questionAnswer.question}</p>
                                <Chevron className={cn(currentExpandedId === questionAnswer.id && 'rotate-180')} />
                            </div>
                            {currentExpandedId === questionAnswer.id && <p className="h4-responsive mt-4 font-normal text-text-primary">{questionAnswer.answer}</p>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
