import React from 'react';

export default function Check(props: React.SVGAttributes<{}>) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="24" height="24" rx="12" fill="currentColor" />
            <g filter="url(#filter0_d_95_121)">
                <path d="M5.71414 13.2934L9.15442 16.0001L17.7141 7.42871" stroke="white" strokeWidth="2.57143" strokeLinecap="round" />
            </g>
            <defs>
                <filter id="filter0_d_95_121" x="0.999804" y="6.14307" width="21.4286" height="18.4309" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.42857" />
                    <feGaussianBlur stdDeviation="1.71429" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_95_121" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_95_121" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
