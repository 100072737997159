import { features } from '@app/constants';
import Check from '@app/components/icons/check';
import TextInput from '@app/components/common/input/TextInput';
import Button from '@app/components/common/button/button';
import { useScrollContext } from '@app/context/scroll-context';
import { useSubscribeMutation } from '@app/store/formUrl/api';
import { toast } from 'react-toastify';
import { ChangeEvent, FormEvent, FormEventHandler, useState } from 'react';
import { ToastId } from '@app/constants/toast-id';
import { isEmpty } from 'lodash';

export default function Features() {
    const { featuresRef } = useScrollContext();
    const [subscribe, { isLoading }] = useSubscribeMutation();
    const [emailAddress, setEmailAddress] = useState<string | null>(null);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(event.target.value);
    };

    const handleSubscribe = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (emailAddress) {
            await subscribe({ emailAddress }).then((response) => {
                if ('data' in response) {
                    toast.success('Thank you for subscribed', { toastId: ToastId.SUCCESS_TOAST });
                } else {
                    toast.error('Failed on subscription', { toastId: ToastId.ERROR_TOAST });
                }
            });
        }
    };
    return (
        <section ref={featuresRef} id="feature" className="relative flex flex-col justify-center py-[60px] text-center sm:min-h-screen sm:py-[120px]">
            <div className="custom-gradient-orange absolute -bottom-60 left-0 opacity-0 sm:opacity-100"></div>
            <p className="h2-responsive text-text-primary">{features.title}</p>
            <p className="body1-responsive mb-9 mt-2 text-text-secondary sm:mb-14">{features.description}</p>
            <div className="flex w-full max-w-[808px] flex-wrap justify-center gap-4 sm:gap-6">
                {features.items.map((feature) => {
                    return (
                        <div key={feature} className="flex items-center gap-3">
                            <Check className="h-3 w-3 text-secondary-600 sm:h-6 sm:w-6" />
                            <p className="body3-responsive font-medium text-text-secondary">{feature}</p>
                        </div>
                    );
                })}
            </div>
            <form onSubmit={handleSubscribe} className="mt-9 flex w-full flex-col items-center gap-4 sm:mt-12 sm:gap-8">
                <p className="h4-responsive font-semibold">Subscribe to get notified</p>
                <TextInput type="email" onChange={handleOnChange} placeholder="Your email address" className="max-w-[750px] rounded-[12px] border border-border-primary" />
                <Button disabled={isEmpty(emailAddress)} isLoading={isLoading}>
                    Subscribe
                </Button>
            </form>
        </section>
    );
}
