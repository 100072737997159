import React from 'react';

export default function DocsIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_100_639)">
                <rect x="5" y="0.5" width="26" height="26" rx="13" fill="white" shapeRendering="crispEdges" />
                <g filter="url(#filter1_d_100_639)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.8152 7.63234V9.091L13.547 7.41445L15.1551 5.73804H22.2472C23.3175 5.73804 24.1851 6.60565 24.1851 7.6759V19.3238C24.1851 20.394 23.3174 21.2616 22.2472 21.2616H13.7526C12.6823 21.2616 11.8147 20.394 11.8147 19.3238V7.67589C11.8147 7.66134 11.8149 7.64682 11.8152 7.63234Z"
                        fill="#9747FF"
                    />
                    <g filter="url(#filter2_d_100_639)">
                        <path d="M15.2043 7.10886L15.1546 5.73804L11.8146 9.09113L13.233 9.11655C14.3442 9.13647 15.2445 8.21958 15.2043 7.10886Z" fill="#C090FF" />
                    </g>
                    <g filter="url(#filter3_d_100_639)">
                        <rect width="5.93776" height="1.24189" rx="0.322976" transform="matrix(-1 0 0 1 20.1025 11.3264)" fill="white" />
                        <rect width="5.93776" height="1.24189" rx="0.322976" transform="matrix(-1 0 0 1 20.1025 13.9343)" fill="white" />
                        <rect width="5.93776" height="1.24189" rx="0.322976" transform="matrix(-1 0 0 1 20.1025 16.5425)" fill="white" />
                        <rect width="1.23703" height="1.24189" rx="0.618517" transform="matrix(-1 0 0 1 21.7114 11.3267)" fill="white" />
                        <rect width="1.23703" height="1.24189" rx="0.618517" transform="matrix(-1 0 0 1 21.7114 13.9346)" fill="white" />
                        <rect width="1.23703" height="1.24189" rx="0.618517" transform="matrix(-1 0 0 1 21.7114 16.5427)" fill="white" />
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_100_639" x="0.666667" y="0.5" width="34.6667" height="34.6667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4.33333" />
                    <feGaussianBlur stdDeviation="2.16667" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_100_639" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_100_639" result="shape" />
                </filter>
                <filter id="filter1_d_100_639" x="10.8455" y="5.41506" width="13.6625" height="16.8156" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.322976" dy="0.322976" />
                    <feGaussianBlur stdDeviation="0.322976" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_100_639" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_100_639" result="shape" />
                </filter>
                <filter id="filter2_d_100_639" x="10.8455" y="5.41506" width="4.68302" height="4.67081" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-0.322976" dy="0.322976" />
                    <feGaussianBlur stdDeviation="0.322976" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_100_639" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_100_639" result="shape" />
                </filter>
                <filter id="filter3_d_100_639" x="13.5186" y="11.0034" width="8.83878" height="7.75016" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.322976" />
                    <feGaussianBlur stdDeviation="0.322976" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_100_639" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_100_639" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
