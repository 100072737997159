import { customizeLink } from '@app/constants';
import CustomizeUrlForm from '../ui/forms/customize-url-form';
import { useScrollContext } from '@app/context/scroll-context';
import { useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CustomizeUrlCardList from '../ui/customize-url-form-preview-list';

export default function CustomizeUrl() {
    const { customizeLinkRef } = useScrollContext();

    const [fingerprint, setFingerPrint] = useState('');

    useEffect(() => {
        // Initialize FingerprintJS
        const loadFingerprint = async () => {
            try {
                const fp = await FingerprintJS.load();

                // Get the visitor identifier when you need it.
                const result = await fp.get();

                // This is the visitor identifier:
                const visitorId = result.visitorId;
                setFingerPrint(visitorId);
            } catch (e) {
                console.log('error in fingerprintjs', e);
            }
        };

        loadFingerprint();
    }, []);

    return (
        <div ref={customizeLinkRef} id="customize-url" className="relative flex w-full flex-col items-center justify-center py-[60px] text-center sm:min-h-screen sm:py-[120px]">
            <div className="custom-gradient-blue absolute right-0 top-1/2 h-full w-full"></div>
            <div className="custom-gradient-blue absolute -top-60 left-0 h-full w-full"></div>
            <p className="h2-responsive text-text-primary">{customizeLink.title}</p>
            <p className="body1-responsive mb-8 max-w-[700px] text-text-secondary sm:mb-14">{customizeLink.description}</p>
            <CustomizeUrlForm fingerprint={fingerprint} />
            <CustomizeUrlCardList fingerprint={fingerprint} />
        </div>
    );
}
