import { SVGAttributes } from 'react';

export default function CopyIcon(props: SVGAttributes<{}>) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M19.5 2H6.9375C6.83437 2 6.75 2.08437 6.75 2.1875V3.5C6.75 3.60312 6.83437 3.6875 6.9375 3.6875H18.5625V19.8125C18.5625 19.9156 18.6469 20 18.75 20H20.0625C20.1656 20 20.25 19.9156 20.25 19.8125V2.75C20.25 2.33516 19.9148 2 19.5 2ZM16.5 5H4.5C4.08516 5 3.75 5.33516 3.75 5.75V18.1883C3.75 18.3875 3.82969 18.5773 3.97031 18.718L8.03203 22.7797C8.08359 22.8312 8.14219 22.8734 8.20547 22.9086V22.9531H8.30391C8.38594 22.9836 8.47266 23 8.56172 23H16.5C16.9148 23 17.25 22.6648 17.25 22.25V5.75C17.25 5.33516 16.9148 5 16.5 5ZM8.20312 20.5672L6.18516 18.5469H8.20312V20.5672ZM15.5625 21.3125H9.70312V17.9844C9.70312 17.4664 9.28359 17.0469 8.76562 17.0469H5.4375V6.6875H15.5625V21.3125Z"
                fill="currentColor"
            />
        </svg>
    );
}
