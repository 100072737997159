import { LinkDetails } from '@app/store/formUrl/types';
import DocsIcon from '@app/components/icons/docs-icon';
import CopyIcon from '@app/components/icons/copy-icon';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/toast-id';
import AnchorLink from '@app/components/common/links/anchor-link';

export default function CustomizeUrlCard({ destinationUrl, mappedPath }: LinkDetails) {
    const customizeUrl = `${window.location.origin}/to/${mappedPath}`;
    const handleCopyCustomizeLink = () => {
        navigator.clipboard.writeText(customizeUrl);
        toast.success('Copied to clipboard', { toastId: ToastId.SUCCESS_TOAST });
    };
    return (
        <div className="flex w-full max-w-[794px] gap-3 rounded-md bg-white-1 p-5 shadow-customizeLinkCard">
            <DocsIcon />
            <div className="flex w-full flex-col gap-2 text-start">
                <AnchorLink target="_blank" href={customizeUrl} className="body2-responsive break-all font-medium">
                    {customizeUrl}
                </AnchorLink>
                <AnchorLink target="_blank" href={destinationUrl} className="body3 break-all font-medium text-neutral-600">
                    {destinationUrl}
                </AnchorLink>
            </div>
            <CopyIcon onClick={handleCopyCustomizeLink} className="cursor-pointer text-neutral-500" />
        </div>
    );
}
