import { useGetLinksQuery } from '@app/store/formUrl/api';
import Loader from './loader';
import CustomizeUrlCard from './cards/customize-url-card';

interface CustomizeUrlCardListProps {
    fingerprint: string;
}

export default function CustomizeUrlCardList({ fingerprint }: CustomizeUrlCardListProps) {
    const { data, isLoading } = useGetLinksQuery(fingerprint);
    if (isLoading) {
        return (
            <div className="h-[200px] w-full">
                <Loader />
            </div>
        );
    }

    if (data) {
        return (
            <div className="mt-9 flex w-full flex-col items-center gap-5 sm:mt-14">
                {data.map((linkDetail) => {
                    return <CustomizeUrlCard key={linkDetail.mappedPath} {...linkDetail} />;
                })}
            </div>
        );
    }
}
