import CustomizeUrl from '@app/components/sections/customize-url';
import FAQ from '@app/components/sections/faq';
import Features from '@app/components/sections/feature';
import HeroSection from '@app/components/sections/hero-section';
import HowItWorks from '@app/components/sections/how-it-work';
import PreviewCustomizeUrl from '@app/components/sections/preview-customize-url';

export default function Home() {
    return (
        <main className="flex w-full max-w-[1440px] flex-col items-center justify-center px-6 sm:px-10">
            <HeroSection />
            <HowItWorks />
            <CustomizeUrl />
            <PreviewCustomizeUrl />
            <Features />
            <FAQ />
        </main>
    );
}
