import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import cn from 'classnames';

import ButtonDrip from '@app/components/common/button/button-drip';
import ButtonLoader from '@app/components/common/button/button-loader';
import { LoaderSizeTypes, LoaderVariantTypes } from '@app/components/ui/loader';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    loaderSize?: LoaderSizeTypes;
    loaderVariant?: LoaderVariantTypes;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, isLoading, disabled, fullWidth, color = 'primary', loaderSize = 'small', loaderVariant = 'scaleUp', onClick, ...buttonProps }, ref: React.Ref<HTMLButtonElement | null>) => {
    let [dripShow, setDripShow] = useState<boolean>(false);
    let [dripX, setDripX] = useState<number>(0);
    let [dripY, setDripY] = useState<number>(0);
    const buttonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => buttonRef.current);

    function dripCompletedHandle() {
        setDripShow(false);
        setDripX(0);
        setDripY(0);
    }

    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isLoading && buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDripShow(true);
            setDripX(event.clientX - rect.left);
            setDripY(event.clientY - rect.top);
        }
        onClick && onClick(event);
    };

    let buttonColorClassNames = '';
    let buttonDripColor = '';

    return (
        <button
            ref={buttonRef}
            onClick={clickHandler}
            className={cn(
                'body2-responsive relative inline-flex w-fit shrink-0 items-center justify-center overflow-hidden rounded-lg bg-button-gradient px-4 py-3 text-center font-semibold text-text-invert transition-all sm:px-6 sm:py-4',
                !disabled ? buttonColorClassNames : 'cursor-not-allowed opacity-50',
                isLoading && 'pointer-events-auto cursor-default focus:outline-none',
                fullWidth && 'w-full',
                className
            )}
            disabled={disabled}
            {...buttonProps}
        >
            <span className={cn(isLoading && 'invisible opacity-0')}>{children}</span>

            {isLoading && <ButtonLoader size={loaderSize} variant={loaderVariant} />}

            {dripShow && <ButtonDrip x={dripX} y={dripY} color={['white', 'gray'].indexOf(color) !== -1 ? 'rgba(0, 0, 0, 0.1)' : buttonDripColor} fullWidth={fullWidth} onCompleted={dripCompletedHandle} />}
        </button>
    );
});

Button.displayName = 'Button';
export default Button;
