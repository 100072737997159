import { howItWorks } from '@app/constants';
import HowItWorksCard from '../ui/cards/how-it-works-card';
import { useScrollContext } from '@app/context/scroll-context';

export default function HowItWorks() {
    const { howItWorksRef } = useScrollContext();
    return (
        <div ref={howItWorksRef} className="relative flex flex-col items-center justify-center py-[60px] text-center sm:min-h-screen sm:py-[120px]">
            <div className="custom-gradient-orange absolute left-0 top-0 h-full w-full rounded-full opacity-0 sm:opacity-100"></div>
            <p className="h2-responsive text-text-primary">{howItWorks.title}</p>
            <p className="body1-responsive mb-9 mt-2 !font-normal text-text-secondary sm:mb-[56px]">{howItWorks.description}</p>
            <div className="grid grid-flow-row grid-cols-1 gap-6 px-4 sm:grid-cols-2 md:px-10 lg:grid-cols-3 xl:gap-[56px] xl:px-20">
                {howItWorks.steps.map((step) => {
                    return <HowItWorksCard key={step.id} {...step} />;
                })}
            </div>
        </div>
    );
}
