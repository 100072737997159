import step1Image from '@app/assets/image/howItWorks/step1.png';
import step2Image from '@app/assets/image/howItWorks/step2.png';
import step3Image from '@app/assets/image/howItWorks/step3.png';
import previewUrlImage from '@app/assets/image/previewUrl.png';

export const heroSection = {
    title: 'Customize Your Form Link To Match Your Brand',
    description: 'Personalize your form link to reflect your brand identity.'
};

export const howItWorks = {
    title: 'How It Works',
    description: 'Simple steps to customize your form link.',
    steps: [
        {
            id: 1,
            title: 'Paste Your Form Link',
            description: 'Copy and paste the Form link you want to customize',
            image: step1Image
        },
        {
            id: 2,
            title: 'Choose Your Custom Link',
            description: 'Enter your preferred custom link to match your brand.',
            image: step2Image
        },
        {
            id: 3,
            title: 'Generate & Share',
            description: 'Get your branded link instantly and start sharing.',
            image: step3Image
        }
    ]
};

export const customizeLink = {
    title: 'Customize Your Form Link',
    description: 'Effortlessly customize your form link to align with your brand and preferences.'
};

export const previewCustomizeLink = {
    title: 'Preview Your Customized Links',
    description: 'Instantly Preview Your Customized link.',
    image: previewUrlImage
};

export const features = {
    title: 'Need More Features?',
    description: 'Subscribe to get notified when following features become available.',
    items: ['View and organize all your form links', 'Update and customize form links', 'View analytics', 'Generate QR codes for your links', 'Your own custom domain for form links']
};

export const faqs = {
    title: 'Frequently Asked Questions',
    questionAnswerList: [
        {
            id: 1,
            question: 'How does the URL customization work?',
            answer: 'Simply paste your Form link, choose a custom URL, and generate your branded link instantly.'
        },
        {
            id: 2,
            question: 'Is this service really free?',
            answer: 'Yes, our URL customization service is completely free to use.'
        },
        {
            id: 3,
            question: 'What happens to my customized URLs?',
            answer: "Your customized URLs are linked to your browser identity using FingerprintJS so when you load the page on the same browser, you'll see the list of your customized URLs."
        }
    ]
};
