import Image, { StaticImageData } from 'next/image';

interface HowItWorksProps {
    title: string;
    description: string;
    image: StaticImageData;
}
export default function HowItWorksCard({ title, description, image }: HowItWorksProps) {
    return (
        <div className="flex flex-col gap-4 rounded-lg bg-white-1 px-[18px] pb-6 pt-4 text-center text-text-secondary shadow-card">
            <div className="h-full max-h-[198px]">
                <Image className="h-full w-full object-fill" src={image} alt="card" />
            </div>
            <p className="body2-responsive font-bold">{title}</p>
            <p className="body2-responsive">{description}</p>
        </div>
    );
}
