import { heroSection } from '@app/constants';
import React from 'react';
import Button from '@app/components/common/button';
import { useScrollContext } from '@app/context/scroll-context';
export default function HeroSection() {
    const { customizeLinkRef, scrollToSection } = useScrollContext();
    return (
        <div className="relative flex flex-col items-center justify-center py-[60px] text-center sm:min-h-calc-68 sm:py-[120px]">
            <div className="custom-gradient-orange absolute inset-0 h-full w-full -rotate-[145deg] opacity-0 sm:opacity-100"></div>
            <p className="h1-responsive max-w-[890px] text-text-primary">{heroSection.title}</p>
            <p className="h4-responsive mt-3 max-w-[610px] font-normal text-text-secondary sm:mt-6">{heroSection.description}</p>
            <Button onClick={() => scrollToSection(customizeLinkRef)} className="my-5 sm:my-8">
                Start For Free
            </Button>
            <p className="body3-responsive font-medium italic text-text-contrast">No registration required. Start customizing now!</p>
        </div>
    );
}
