export function isValidFormUrl(url: string): boolean {
    const googleFormUrlPattern = /^https:\/\/(docs\.google\.com\/forms\/d\/e\/|forms\.gle\/)[\w-]+(\/viewform)?(\?usp=[\w-]+)?$/;
    const tallyPattern = /^https:\/\/tally\.so\/r\/[a-zA-Z0-9]+?$/;
    const typeformPattern = /^https:\/\/[a-zA-Z0-9\-]+\.typeform\.com\/to\/[a-zA-Z0-9]+?$/;
    const microsoftFormPattern = /^https:\/\/forms\.office\.com\/(Pages\/ResponsePage\.aspx\?id=[\w-]+(?:\?.*)?|e\/[\w-]+(?:\?.*)?|r\/[a-zA-Z0-9]+(?:\?.*)?)$/;
    const jotformPattern = /^https:\/\/form\.jotform\.com\/(?:[a-zA-Z0-9]+\/)?[a-zA-Z0-9]+$/;
    const wufooPattern = /^https:\/\/[a-zA-Z0-9\-]+\.wufoo\.com\/forms\/[a-zA-Z0-9\-]+?$/;
    const surverMonkeyPattern = /^https:\/\/www\.surveymonkey\.com\/r\/[a-zA-Z0-9]+?$/;

    return googleFormUrlPattern.test(url) || tallyPattern.test(url) || typeformPattern.test(url) || microsoftFormPattern.test(url) || jotformPattern.test(url) || wufooPattern.test(url) || surverMonkeyPattern.test(url);
}

export function isValidRelativeURL(url: string): boolean {
    // Define a regular expression to match valid relative URLs
    const relativeURLPattern = /^[\w\d\-._~:?#\[\]@!$&'()*+,;=%]+$/;

    // Check if the URL matches the pattern
    return relativeURLPattern.test(url);
}
