import { previewCustomizeLink } from '@app/constants';
import Image from 'next/image';

export default function PreviewCustomizeUrl() {
    return (
        <div className="relative flex flex-col items-center py-[60px] text-center sm:min-h-screen sm:py-[120px]">
            <div className="custom-gradient-blue absolute bottom-0 left-0 opacity-0 sm:opacity-100"></div>
            <p className="h2-responsive text-text-primary">{previewCustomizeLink.title}</p>
            <p className="body1-responsive my-2 mb-9 text-text-secondary sm:mb-14">{previewCustomizeLink.description}</p>
            <div className="h-full w-full sm:w-[560px]">
                <Image src={previewCustomizeLink.image} alt="preview image" className="object-fill" />
            </div>
        </div>
    );
}
